//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #000066;
$secondary: #2e6897;
$body-bg: #EAF0F4;
$chat-bg: #e6f1f8;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);