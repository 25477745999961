/*================================================================================
	Item Name: Vuexy
1784
9ol - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "../../@core/scss/base/core/colors/palette-variables.scss";

body::-webkit-scrollbar {
	width: 5px;
	/* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
	background:
		/*#020018*/
		transparent;
	/* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
	background-color:
		/*#16b2f0*/
		$info ;
	/* color of the scroll thumb */
	border-radius: 5px;
	/* roundness of the scroll thumb */
	border: none;
	/* creates padding around scroll thumb */
}

.input-groups {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
}

.telephoneInput,
.select__control {
	width: 25rem !important;
}

.email-input {
	width: 100% !important;
}

.flex-end {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.form-label {
	font-size: $font-size-base * 1.15;
}

.css-k3ockt-control {
	width: 100% !important;
}

.header-navbar-shadow {
	background: transparent !important;
}

.input-group-color {
	background-color: transparent !important;
}

.header-navbar-shadow {
	padding-bottom: 300px;
	background-color: #000066 !important;
	z-index: 0 !important;
}

.main-menu {
	margin: 17px 10px;
	border-radius: 10px;
	height: 97%;
}

.card-congratulations {
	background: linear-gradient(118deg, #5a83dc, rgba(90, 131, 220, 0.8));
	border: 1px solid #5a83dc;
}

.data-box {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-left: -20vw;
}

.bg-feather-icon {
	width: 10rem !important;
	height: 10rem !important;
	opacity: 25%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.z-1 {
	z-index: 1;
}

.chat-attendance {
	background-color: $chat-bg;
}

.baloon-chat {
	border: 1px solid $secondary;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	color: black !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
	color: black !important;
}

// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
// * {
// 	font-family: 'Lato', 'Montserrat', sans-serif;
// 	font-size: 1.02em;
// 	line-height: 1.4;
// }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

* {
	font-family: 'Inter', 'Montserrat', sans-serif;
	font-size: 1.02em;
	line-height: 1.4;
}

.modal-contract-sizing {
	width: 53vw;
	background-color: red;
}

.edit-icon-hover:hover {
	cursor: pointer;
}

.helpdeskMonitoringCard {
	transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.helpdeskMonitoringCard:hover {
	transform: scale(1.02);
}

.helpdeskMonitoringCardBlurr {
	filter: blur(2px);
	transition: filter 0.2s ease;
}

.cursor {
	cursor: pointer;
}

.cursor-grab {
	cursor: grab;
}

.image-container img {
	max-width: 100%;
	height: auto;
	object-fit: contain;
}